import { computed, ref, watchEffect, watch } from "vue";
import { useStore } from "vuex";
import isPDF from "@/shared/utils/validations/isPDF";
import isAudio from "@/shared/utils/validations/isAudio";
import isVideo from "@/shared/utils/validations/isVideo";
import { ref as refDb, set, get, child, onValue } from "firebase/database";
import database from "@/firebase/config";
import { useRouter } from "vue-router";
const useContentAdd = () => {
  const store = useStore();
  const router = useRouter();
  const modal = computed(
    () => store.getters["admins/myLibraryModal"].contentAdd
  );
  const menu = ref(null);
  const payload = ref({
    level_data: null,
    level_id: null,
    sub_level_id: null,
    title: "",
    description: "",
    infoData: [],
    files: [],
  });
  const isCreatingContent = ref(false);
  const contentPrevisualize = ref({
    level: "",
    sub_level: "",
    subtitle: "",
    content: [],
  });

  const filesRemove = ref([]);
  const textsRemove = ref([]);

  const isTest = ref(false);

  const isValidPayload = (isPreview = false) => {
    const errors = [];
    const payloadData = payload.value;
    let errorContent = false;
    if (!isPreview) {
      Object.entries(payloadData).forEach(([key, value]) => {
        if (Array.isArray(value) && value.length === 0) {
          if (key === "files") errorContent = true;
        }
        if (!value || value === "") {
          if (key === "level_id") errors.push("level");
          else if (key === "sub_level_id") errors.push("sub-level");
          else if (
            key !== "infoData" &&
            key !== "level_data" &&
            !key.includes("content")
          )
            errors.push(key);
        }
      });
    }
    if (errors.length > 0 || (errorContent && !isPreview)) {
      let message = errors.length > 0 ? "The fields " : "";
      errors.forEach((error, index) => {
        message +=
          index === errors.length - 1 ? `${error} are required` : `${error}, `;
      });

      errorContent
        ? errors.length > 0
          ? (message += " and add content is also required")
          : (message =
              "Add content is required (miminum one text and one file)")
        : "";
      openToast(message, "error");
      return { success_valid: false, new_payload: {} };
    } else {
      payloadData.infoData.forEach((p, index) => {
        if (p.type === "text") {
          if (!p.hasOwnProperty("isUrl")) {
            payloadData[`content[${p.type}][${index}][${"number"}]`] = index;
            payloadData[`content[${p.type}][${index}][${"content"}]`] = p.value;
          }
        } else {
          if (!p.hasOwnProperty("isUrl")) {
            let found = payloadData.files.find(
              (f) => typeof f === "object" && f.name === p.name
            );
            if (found) {
              payloadData[`content[${p.type}][${index}][${"number"}]`] = index;
              payloadData[`content[${p.type}][${index}][${"content"}]`] = found;
            }
          } else {
            delete payloadData[`content[${p.type}][${index}][${"content"}]`];
            delete payloadData[`content[${p.type}][${index}][${"number"}]`];
            // delete payloadData[`content[${p.type}][${index}][${"content"}]`]
          }
        }
      });
      return { success_valid: true, new_payload: { ...payloadData } };
    }
  };

  const getTitles = (max) => {
    let data = {};
    for (let i = 0; i < max; i++) {
      data[i] = {
        status: 0,
      };
    }
    return data;
  };

  const getInitialProgress = (json) => {
    let data = {};
    json.forEach((level) => {
      let i = 0;
      let all = 0;
      let titles_content = {};
      level.title_content.forEach((title) => {
        all += title.subtitles_count;
        titles_content[i] = {
          complete: 0,
          all: title.subtitles_count,
          subtitles: getTitles(title.subtitles_count),
        };
        i++;
      });
      data[level.level_name_short] = {
        ...titles_content,
        all: all,
        complete: 0,
      };
    });
    data.status = "offline";
    data.last_status = "offline";
    return data;
  };

  const updateInfoStudentsFirebase = async () => {
    const { success, json } = await store.dispatch("shared/levelsInfo");
    const studentsData = store.getters['admins/getMemberStudents'].tables.basicInfo.data?.map(item => item.token_firebase).filter(item => item)
    if (success) {
      const dbRef = refDb(database);
      get(child(dbRef, `Spanish/`))
        .then((snapshot) => {
          let students = snapshot.val()
          
          Object.entries(students).forEach(([key_student, student]) => {
            if(studentsData.some(item => item == key_student)){
              let initialProgressStudent = getInitialProgress(json);
              Object.entries(initialProgressStudent).forEach(
                ([key_progress, value]) => {
                  if (
                    key_progress !== "last_status" &&
                    key_progress !== "status"
                  ) {
                    value.complete = student[key_progress]?.complete;
                    Object.entries(initialProgressStudent[key_progress]).forEach(
                      ([key_progress_tile, value2]) => {
                        if (
                          key_progress_tile !== "complete" &&
                          key_progress_tile !== "all"
                        ) {
                          if (
                            student[key_progress][key_progress_tile]?.complete !==
                            undefined
                          )
                            value2.complete =
                              student[key_progress][key_progress_tile]?.complete;
                          if (
                            student[key_progress][key_progress_tile]?.subtitles !==
                            undefined
                          ) {
                            Object.entries(
                              initialProgressStudent[key_progress][key_progress_tile]?.subtitles
                            ).forEach(([key3, value3]) => {
                              if (student[key_progress][key_progress_tile].subtitles[key3] !== undefined) {
                                initialProgressStudent[key_progress][key_progress_tile].subtitles[key3] =
                                  student[key_progress][key_progress_tile]?.subtitles[key3];
                              }
                            });
                          }
                        }
                      }
                    );
                  } else {
                    initialProgressStudent[key_progress] = value;
                  }
                }
              );
              set(
                refDb(database, "Spanish/" + key_student),
                initialProgressStudent
              );
            }
          });
          openToast("Progress data student updated", "success");
          isCreatingContent.value = false;
        })
        .catch((error) => {
          console.log(error);
          isCreatingContent.value = false;
          openToast(
            "Something happend updating firebase info students",
            "error"
          );
        });
    } else {
      openToast("Something happend updating firebase info students", "error");
      console.log("AQUI");
      isCreatingContent.value = false;
    }
  };

  const createContent = async (mode = "created") => {
    isCreatingContent.value = true;
    const { success_valid, new_payload } = isValidPayload();

    // return;
    if (success_valid) {
      delete new_payload.infoData;
      delete new_payload.level_data;
      delete new_payload.files;
      if (mode === "created") {
        const { success } = await store.dispatch(
          "admins/createContentLibrary",
          {
            payload: new_payload,
          }
        );
        if (success) {
          let previsualize = store.getters["admins/previsualize"];
          store.commit("shared/updateLibrary", { isLoading: true });
          store.commit("admins/contentAddPayload", {
            isUpdate: true,
          });
          await store.dispatch("admins/getLibrary",{levelIdUpdate: new_payload.level_id,isUpdate:true});
          resetPayload();
          updateInfoStudentsFirebase();
          toggleModalContent(false);
          if (previsualize.isView) router.push({ name: "admin-library" });
        } else {
          resetPayload();
          isCreatingContent.value = false;
        }
      } else {
        let payload_update = new_payload;
        delete payload_update.title_sub_level_id;
        const { success } = await store.dispatch(
          "admins/updateContentLibrary",
          {
            payload: {
              ...payload_update,
              files_remove_link: filesRemove.value,
              text_remove_id: textsRemove.value,
            },
            id: payload.value.title_sub_level_id,
          }
        );
        if (success) {
          resetPayload();
          toggleModalContent(false);
          store.commit("admins/contentAddPayload", {
            isUpdate: true,
            levelIdUpdate: payload.value.level_id,
          });
          router.push({ name: "admin-library" });
        }
        isCreatingContent.value = false;
      }
    } else isCreatingContent.value = false;
  };

  const deleteContent = async(id, payload, levelId) => {
    const { success } = await store.dispatch(
      "admins/deleteContentLibrary",
      {
        payload: payload,
        id: id,
      }
    );
    if (success) {
      await store.dispatch("admins/getLibrary",{levelIdUpdate: levelId,isUpdate:true});
      router.push({ name: "admin-library" });
    }
  }

  const selectFile = (e) => {
    if (e.target.files.length > 0) {
      if (
        isPDF(e.target.files[0]) ||
        isAudio(e.target.files[0]) ||
        isVideo(e.target.files[0])
      ) {
        const file = e.target.files[0];
        const idFile = parseInt(e.target.getAttribute("object"));
        const findIndex = payload.value.infoData.findIndex(
          (item) => item.id === idFile
        );
        payload.value.infoData[findIndex].name = file.name;
        payload.value.files.push(file);

        if (payload.value.infoData[findIndex].hasOwnProperty("isUrl")) {
          payload.value.files = payload.value.files.filter(
            (p) => p !== payload.value.infoData[findIndex].value
          );
          delete payload.value.infoData[findIndex].isUrl;
          filesRemove.value.push(payload.value.infoData[findIndex].value);
        }

        if (file.type === "application/pdf")
          payload.value.infoData[findIndex].type = "pdf";
        else if (file.type.includes("video"))
          payload.value.infoData[findIndex].type = "video";
        else payload.value.infoData[findIndex].type = "audio";
      } else {
        openToast("Only Pdf files are allowed", "error");
      }
    }
  };

  const openToast = (message, status) => {
    const notification = { isOpen: true };
    notification.message = message;
    notification.status = status;
    store.commit("shared/uiNotificationStatus", notification);
    store.dispatch("shared/resetNotificationStatus");
  };

  const items = ref([
    {
      label: "Add text",
      icon: "",
      command: () => {
        payload.value.infoData.push({
          id: payload.value.infoData.length,
          type: "text",
          value: "",
        });
      },
    },
    {
      label: "Add file",
      icon: "",
      command: () => {
        payload.value.infoData.push({
          id: payload.value.infoData.length,
          type: "file",
          name: "",
        });
      },
    },
  ]);

  const sublevels = ref([]);
  const currentSubLevels = ref({ data: [], isLoading: false });
  const currentSubLevel = ref("");
  const currentLevel = ref(null);
  const listings = computed(() => store.getters["shared/getListings"].levels);

  const resetPayload = () => {
    payload.value.description = "";
    payload.value.title = "";
    payload.value.files = [];
    payload.value.infoData = [];
    payload.value.level_data = null;
    payload.value.sub_level_id = null;

    textsRemove.value = [];
    filesRemove.value = [];
  };
  const setInfoDataSubtitle = (data) => {
    data.forEach((info, index) => {
      let item = {
        id: index,
        type: info.type,
        value: info.content,
        isUrl: true,
        idFile: info.id,
      };
      if (info.type !== "text") {
        item.name = info.content;
        payload.value.files.push(info.content);
      }
      payload.value.infoData.push(item);
    });
  };

  const toggleModalContent = (open, isEdit = false, isCancel = false) => {
    if (isEdit) {
      if (isCancel) {
        store.commit("admins/toggleModalContent", { isOpen: open });
        let previsualize = store.getters["admins/previsualize"];
        if (!previsualize.isView) {
          resetPayload();
        }
      } else {
        const { currentLevel, currentLevelContent } =
          store.getters["shared/getCurrentLibrary"];
        const { levels } = store.getters["shared/getListings"];
        resetPayload();

        payload.value.level_id = currentLevel.level_id;
        payload.value.sub_level_id = currentLevelContent.title_id;
        payload.value.title = currentLevelContent.content.subtitle_name;
        payload.value.description =
          currentLevelContent.content.subtitle_content_description;
        payload.value.title_sub_level_id =
          currentLevelContent.content.subtitle_id;
        const levelData = levels.data.find(
          (level) => level.id === currentLevel.level_id
        );
        payload.value.level_data = levelData;
        if (currentLevelContent.content.content.length > 0) {
          setInfoDataSubtitle(currentLevelContent.content.content);
        }
        store.commit("admins/contentAddPayload", {
          data: payload.value,
          isEdit: true,
        });
        setTimeout(() => {
          store.commit("admins/toggleModalContent", { isOpen: open });
        }, 1000);
      }
    } else store.commit("admins/toggleModalContent", { isOpen: open });
  };

  const changeTextContent = (item) => {
    const infoDataFind = payload.value.infoData.find((p) => p.id === item.id);
    if (infoDataFind.hasOwnProperty("isUrl")) {
      delete infoDataFind.isUrl;
      textsRemove.value.push(infoDataFind.idFile);
    }
  };

  const selectLevel = (e) => {
    sublevels.value = e.value.sublevels;
    payload.value.level_id = e.value.id;
    payload.value.sub_level_id = e.value.sublevels[0].id;
  };

  const toggle = (e) => {
    menu.value.toggle(e);
  };

  const addFile = (fileId) => {
    let element = document.getElementById(fileId);
    element.click();
  };

  const goToPrevisualize = () => {
    const { success_valid, new_payload } = isValidPayload();
    if (success_valid) {
      store.commit("admins/previsualize", { data: new_payload, isView: true });
      toggleModalContent(false);
      // resetPayload()
      router.push({ name: "admin-library-content" });
    }
  };

  const goToLibrary = () => {
    toggleModalContent(true);
    // router.push({name:'admin-library'})
  };

  const getContentPrevisualize = () => {
    const { data } = store.getters["admins/previsualize"];
    const { levels } = store.getters["shared/getCurrentLibrary"];
    const levelFound = levels.find((level) => level.level_id === data.level_id);
    const sublevelFound = levelFound.level_content.find(
      (sublevel) => sublevel.title_id === data.sub_level_id
    ).title_name;
    contentPrevisualize.value.level = levelFound.level_name;
    contentPrevisualize.value.sub_level = sublevelFound;
    contentPrevisualize.value.subtitle = data.title;
    let positionFile = 0;
    data.infoData.forEach((dataInput) => {
      if (dataInput.type !== "text") {
        dataInput.value = data.files[positionFile];
        positionFile++;
      }
    });
    contentPrevisualize.value.content = data.infoData;
    contentPrevisualize.value.content.forEach((dataContent) => {
      if (dataContent.type === "pdf" && !dataContent.hasOwnProperty("isUrl")) {
        var reader = new FileReader();
        reader.addEventListener(
          "load",
          function () {
            dataContent.source = reader.result;
          },
          false
        );
        if (dataContent.value) {
          dataContent.name = dataContent.value.name;
          reader.readAsDataURL(dataContent.value);
        }
      }
    });
    setTimeout(() => {
      contentPrevisualize.value.content.forEach((dataContent) => {
        if (!dataContent.hasOwnProperty("isUrl")) {
          if (dataContent.type === "audio") {
            const audio = document.getElementById("audio" + dataContent.id);
            if (audio) {
              var url = URL.createObjectURL(dataContent.value);
              audio.controls = "true";
              audio.src = url;
            }
          } else if (dataContent.type === "video") {
            const video = document.getElementById("video" + dataContent.id);
            if (video) {
              var url = URL.createObjectURL(dataContent.value);
              video.controls = "true";
              video.src = url;
            }
          }
        }
      });
    }, 1000);
  };

  const orderUp = (info) => {
    if (payload.value.infoData.length > 1) {
      const index = payload.value.infoData.findIndex(
        (test) => test.id === info.id
      );
      if (index > 0) {
        const index_new = index - 1;
        const temp = payload.value.infoData[index_new];
        payload.value.infoData[index_new] = { ...info };
        payload.value.infoData[index] = { ...temp };
        setTimeout(() => {
          if (
            payload.value.infoData[index_new].type !== "text" &&
            !payload.value.infoData[index_new].hasOwnProperty("url")
          ) {
            if (payload.value.infoData[index_new].type === "audio") {
              const audio = document.getElementById("audio" + dataContent.id);
              if (audio) {
                var url = URL.createObjectURL(dataContent.value);
                audio.controls = "true";
                audio.src = url;
              }
            } else if (payload.value.infoData[index_new].type === "video") {
              const video = document.getElementById(
                "video" + payload.value.infoData[index_new].id
              );
              if (video) {
                var url = URL.createObjectURL(
                  payload.value.infoData[index_new].value
                );
                video.controls = "true";
                video.src = url;
              }
            }
          }
        }, 1000);
        setTimeout(() => {
          if (
            payload.value.infoData[index].type !== "text" &&
            !payload.value.infoData[index].hasOwnProperty("url")
          ) {
            if (payload.value.infoData[index].type === "audio") {
              const audio = document.getElementById("audio" + dataContent.id);
              if (audio) {
                var url = URL.createObjectURL(dataContent.value);
                audio.controls = "true";
                audio.src = url;
              }
            } else if (payload.value.infoData[index].type === "video") {
              const video = document.getElementById(
                "video" + payload.value.infoData[index].id
              );
              if (video) {
                var url = URL.createObjectURL(
                  payload.value.infoData[index].value
                );
                video.controls = "true";
                video.src = url;
              }
            }
          }
        }, 1000);
      }
    }
  };

  const orderDown = (info) => {
    if (payload.value.infoData.length > 1) {
      const index = payload.value.infoData.findIndex(
        (test) => test.id === info.id
      );
      if (index < payload.value.infoData.length - 1) {
        const index_new = index + 1;
        const temp = payload.value.infoData[index_new];
        payload.value.infoData[index_new] = { ...info };
        payload.value.infoData[index] = { ...temp };
        setTimeout(() => {
          if (
            payload.value.infoData[index_new].type !== "text" &&
            !payload.value.infoData[index_new].hasOwnProperty("url")
          ) {
            if (payload.value.infoData[index_new].type === "audio") {
              const audio = document.getElementById("audio" + dataContent.id);
              if (audio) {
                var url = URL.createObjectURL(dataContent.value);
                audio.controls = "true";
                audio.src = url;
              }
            } else if (payload.value.infoData[index_new].type === "video") {
              const video = document.getElementById(
                "video" + payload.value.infoData[index_new].id
              );
              if (video) {
                var url = URL.createObjectURL(
                  payload.value.infoData[index_new].value
                );
                video.controls = "true";
                video.src = url;
              }
            }
          }
        }, 1000);
        setTimeout(() => {
          if (
            payload.value.infoData[index].type !== "text" &&
            !payload.value.infoData[index_new].hasOwnProperty("url")
          ) {
            if (payload.value.infoData[index].type === "audio") {
              const audio = document.getElementById("audio" + dataContent.id);
              if (audio) {
                var url = URL.createObjectURL(dataContent.value);
                audio.controls = "true";
                audio.src = url;
              }
            } else if (payload.value.infoData[index].type === "video") {
              const video = document.getElementById(
                "video" + payload.value.infoData[index].id
              );
              if (video) {
                var url = URL.createObjectURL(
                  payload.value.infoData[index].value
                );
                video.controls = "true";
                video.src = url;
              }
            }
          }
        }, 1000);
      }
    }
  };

  watch(() => {
    const { isView } = store.getters["admins/previsualize"];
    const isTest_ = isTest.value;
    if (isView && !isTest_) {
      isTest.value = true;
      getContentPrevisualize();
    } else if (isTest_) {
      isTest.value = false;
    }
  });

  watchEffect(() => {
    let contentAddPayload = store.getters["admins/contentAddPayload"].data;
    if (contentAddPayload) {
      payload.value = contentAddPayload;
      sublevels.value = payload.value.level_data
        ? payload.value.level_data.sublevels
        : [];
    }
  });

  const deleteItem = (id) => {
    let foundDelete = payload.value.infoData.find((p) => p.id === id);
    payload.value.infoData = payload.value.infoData.filter(
      (item) => item.id !== id
    );
    if (foundDelete.hasOwnProperty("isUrl")) {
      if (foundDelete.type === "text") {
        textsRemove.value.push(foundDelete.idFile);
      } else {
        filesRemove.value.push(foundDelete.value);
      }
    }
  };

  const previsualize = computed(() => {
    return store.getters["admins/previsualize"];
  });

  const contentAdd = computed(() => {
    return store.getters["admins/contentAddPayload"];
  });

  return {
    changeTextContent,
    orderUp,
    orderDown,
    contentAdd,
    filesRemove,
    textsRemove,
    previsualize,
    contentPrevisualize,
    goToLibrary,
    selectFile,
    goToPrevisualize,
    createContent,
    modal,
    resetPayload,
    deleteItem,
    listings,
    currentLevel,
    isCreatingContent,
    currentSubLevels,
    payload,
    selectLevel,
    sublevels,
    currentSubLevel,
    toggleModalContent,
    items,
    toggle,
    menu,
    addFile,
    deleteContent
  };
};
export default useContentAdd;
